import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ag-admin-question-delete',
  templateUrl: './admin-question-delete.component.html',
  styleUrls: ['./admin-question-delete.component.css']
})
export class AdminQuestionDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
