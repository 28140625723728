import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ag-course-management',
  templateUrl: './course-management.component.html',
  styleUrls: ['./course-management.component.css']
})
export class CourseManagementComponent implements OnInit {
  constructor() { }

  ngOnInit() {

  }

}
