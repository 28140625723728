import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ag-student-management',
  templateUrl: './student-management.component.html',
  styleUrls: ['./student-management.component.css']
})
export class StudentManagementComponent implements OnInit {
  
  constructor() { }

  ngOnInit() {

  }

}
