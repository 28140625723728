import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'ag-member-dashboard',
  templateUrl: './member-dashboard.component.html',
  styleUrls: ['./member-dashboard.component.css']
})
export class MemberDashboardComponent implements OnInit {
  

  constructor() { }

  ngOnInit() {

  }

  

}
