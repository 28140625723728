
export class Skill {
  constructor( 
    public id:string, 
    public skill:string, 
    public description:string, 
    public user_id:string, 
    public image:string,
    public lesson_link:string, 
    public status_id:string ) {
  }
}
